:root {
  --tsl-brand-transition-time: 0.3s;
  --tsl-brand-border-radius: 30px;
  --tsl-brand-card-padding: 32px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiSelect-root:focus {
  background-color: transparent !important;
}

::-webkit-scrollbar-track {
  padding: 2px 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-radius: var(--tsl-brand-border-radius);
  background-color: rgba(255, 255, 255, 0.105);
}
